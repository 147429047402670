import BaseCrudApi from './BaseCrudApi';
import { IRecordTypePage, RecordTypePageDTO } from '../types';

export default class RecordTypePageAPI extends BaseCrudApi<IRecordTypePage> {
  protected ENDPOINT = 'record-pages';

  create(recordTypePage?: RecordTypePageDTO): Promise<IRecordTypePage> {
    return this.client.post<IRecordTypePage>(this.ENDPOINT, recordTypePage);
  }

  getPagesByRecordType(recordTypeId: IRecordTypePage['id']): Promise<IRecordTypePage[]> {
    return this.client.get<IRecordTypePage[]>(
      `${this.ENDPOINT}?${new URLSearchParams({ recordTypeId }).toString()}`
    );
  }
}
