import BaseCrudApi from './BaseCrudApi';
import { IUser } from '../types';

export default class UserAPI extends BaseCrudApi<IUser> {
  protected ENDPOINT = 'users';

  async searchUsers(userName?: string, userIds?: string[]): Promise<IUser[]> {
    const params = new URLSearchParams();
    if (userName !== undefined) {
      params.set('name', userName);
    }
    if (userIds?.length) {
      params.set('ids', userIds.join(','));
    }

    return this.client.get<IUser[]>(`${this.ENDPOINT}?${params.toString()}`);
  }
}
