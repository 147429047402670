import type { JSONSchema7 } from 'json-schema';

import {
  IRecordType,
  IRecordTypeOrderItemDTO,
  IRecordTypeRowOrderDTO,
  IReusableInfo,
  RecordTypeDTO,
} from '../types';
import BaseCrudApi from './BaseCrudApi';
import { ApiClientFetchOptions } from './AbstractApiClient';

export default class RecordTypeAPI extends BaseCrudApi<IRecordType> {
  protected ENDPOINT = 'record-types';

  create(
    recordType: RecordTypeDTO,
    workspaceId: string,
    sectionName?: string
  ): Promise<IRecordType> {
    return this.client.post<IRecordType>(this.ENDPOINT, {
      recordType,
      workspaceId,
      sectionName: sectionName || null,
    });
  }

  createInWorkspace(workspaceId: string, recordType?: RecordTypeDTO): Promise<IRecordType> {
    return this.client.post<IRecordType>(`${this.ENDPOINT}/workspaces/${workspaceId}`, recordType);
  }

  createInWorkspaceSection(
    workspaceId: string,
    sectionId: string,
    recordType?: RecordTypeDTO
  ): Promise<IRecordType> {
    return this.client.post<IRecordType>(
      `${this.ENDPOINT}/workspaces/${workspaceId}/recordTypeSections/${sectionId}`,
      recordType
    );
  }

  getByWorkspace(
    workspaceId: string,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<IRecordType[]> {
    return this.client.get<IRecordType[]>(
      `${this.ENDPOINT}?${new URLSearchParams({ workspaceId })}`,
      fetchOptions
    );
  }

  get(id: string): Promise<IRecordType> {
    return this.client.get<IRecordType>(`${this.ENDPOINT}/${id}`);
  }

  getJsonSchema(id: string): Promise<JSONSchema7> {
    return this.client.get<JSONSchema7>(`${this.ENDPOINT}/${id}/jsonSchema`);
  }

  import(body: FormData): Promise<IRecordType[]> {
    return this.client.post(`${this.ENDPOINT}/import`, body);
  }

  updateExternalRecordTypeFields(recordTypeId: string, fieldNames: string[]): Promise<IRecordType> {
    return this.client.put<IRecordType>(
      `${this.ENDPOINT}/${recordTypeId}/external-fields`,
      fieldNames
    );
  }

  getReusableInfo(sharedRecordTypeId: string): Promise<IReusableInfo> {
    return this.client.get<IReusableInfo>(`${this.ENDPOINT}/${sharedRecordTypeId}/reusable-info`);
  }

  moveRecords(
    recordTypeId: IRecordType['id'],
    newRecordTypeOrder: IRecordTypeRowOrderDTO
  ): Promise<IRecordTypeOrderItemDTO[]> {
    return this.client.patch<IRecordTypeOrderItemDTO>(
      `${this.ENDPOINT}/${recordTypeId}/move-records`,
      newRecordTypeOrder
    ) as Promise<IRecordTypeOrderItemDTO[]>;
  }
}
