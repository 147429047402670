import { ICacheStrategyImplementation } from '../../types/CacheStrategy';

export interface ICacheFirstStrategyConfig {
  ttl: number;
}

interface IWithTimestamp<V = unknown> {
  timestamp: number;
  value: V;
}

export const cacheFirstStrategy: ICacheStrategyImplementation<ICacheFirstStrategyConfig> = async (
  args,
  valueGetter,
  maestroStorage,
  config: ICacheFirstStrategyConfig
): Promise<unknown> => {
  const currentTimestamp = Date.now();
  const cacheKey = JSON.stringify(args);
  const cachedValue = maestroStorage.get<IWithTimestamp>(cacheKey);

  if (
    !cachedValue ||
    !cachedValue.timestamp ||
    !cachedValue.value ||
    currentTimestamp - cachedValue.timestamp >= config.ttl
  ) {
    maestroStorage.remove(cacheKey);
    return valueGetter(...args).then((value) => {
      maestroStorage.set(cacheKey, { value, timestamp: currentTimestamp });
      return value;
    });
  }

  return cachedValue.value;
};
