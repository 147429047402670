import BaseApi from './BaseApi';
import { ImageTypes } from '../enums';

export default class AssetsAPI extends BaseApi {
  protected ENDPOINT = 'statics';

  getDefaultImages(type: ImageTypes): Promise<string[]> {
    return this.client.get(`${this.ENDPOINT}?type=${type}`);
  }

  getSignedUrls(urls: string[]): Promise<Record<string, string>> {
    return this.client.post(`${this.ENDPOINT}/asset-urls`, urls);
  }
}
