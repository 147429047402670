import { IUserMetadata, IUserViewMetadata, TStageKey } from '../types/UserMetadata';
import BaseCrudApi from './BaseCrudApi';

export default class UserMetadataAPI extends BaseCrudApi<IUserMetadata> {
  protected ENDPOINT = 'user-metadata';
  async get(): Promise<IUserMetadata> {
    return this.client.get<IUserMetadata>(this.ENDPOINT);
  }

  addPassedOnboarding(stageKey: TStageKey): Promise<void> {
    return this.client.patch<void>(`${this.ENDPOINT}/add-passed-onboarding`, {
      key: stageKey,
    });
  }

  resetOnboarding(): Promise<void> {
    return this.client.put<void>(`${this.ENDPOINT}/reset-onboarding`, null);
  }

  setOnboardingCompleted(isCompleted: boolean): Promise<void> {
    return this.client.put<void>(`${this.ENDPOINT}/isOnboardingCompleted`, isCompleted);
  }

  updateViewMetadata(userViewMetadata: IUserViewMetadata): Promise<IUserMetadata> {
    const { viewId, mode, timelineScaleType, lastViewedDate } = userViewMetadata;
    return this.client.patch(`${this.ENDPOINT}`, {
      userViewMetadata: [
        {
          viewId,
          mode,
          timelineScaleType,
          lastViewedDate,
        },
      ],
    }) as Promise<IUserMetadata>;
  }

  updateShowRecordTypeCollaborators(showRecordTypeCollaborators: boolean): Promise<unknown> {
    return this.client.patch(`${this.ENDPOINT}`, {
      showRecordTypeCollaborators,
    }) as Promise<unknown>;
  }
}
