import { IRecordTypeViewsCount, ITableViewRowOrderDTO, IView, ViewDTO } from '../types/View';
import BaseCrudApi from './BaseCrudApi';
import { Cacheable } from '../cache/Cacheable';

export default class ViewApi extends BaseCrudApi<IView> {
  protected ENDPOINT = 'views';

  create(view: ViewDTO): Promise<IView> {
    return this.client.post<IView>(`${this.ENDPOINT}`, view);
  }

  getAll(recordTypeId: string): Promise<IView[]> {
    return this.client.get<IView[]>(`${this.ENDPOINT}?recordTypeId=${recordTypeId}`);
  }

  moveTableRows(viewId: IView['id'], newRowOrder: ITableViewRowOrderDTO): Promise<IView> {
    return this.client.patch(
      `${this.ENDPOINT}/${viewId}/moveTableRows`,
      newRowOrder
    ) as Promise<IView>;
  }

  duplicate(viewId: IView['id']): Promise<IView> {
    return this.client.post<IView>(`${this.ENDPOINT}/${viewId}/copy`, {});
  }

  @Cacheable('STALE_WHILE_REVALIDATE')
  async viewsCount(recordTypeIds: string[]): Promise<IRecordTypeViewsCount> {
    return this.client.get<IRecordTypeViewsCount>(
      `${this.ENDPOINT}/count?recordTypeIds=${recordTypeIds}`
    );
  }
}
