import { CacheStrategy } from '../types/CacheStrategy';
import { MaestroStorage } from '../utils/MaestroStorage';
import {
  ICacheFirstStrategyConfig,
  cacheFirstStrategy,
  staleWhileRevalidateStrategy,
} from './strategies';

interface IDescriptor extends PropertyDescriptor {
  value: (...args: unknown[]) => Promise<unknown>;
}

export function Cacheable(
  strategy: 'CACHE_FIRST',
  config: ICacheFirstStrategyConfig
): CallableFunction;
export function Cacheable(strategy: 'STALE_WHILE_REVALIDATE'): CallableFunction;
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function Cacheable(
  strategy: CacheStrategy,
  config?: ICacheFirstStrategyConfig
): CallableFunction {
  return (_: unknown, method: string, descriptor: IDescriptor) => {
    const cache = new MaestroStorage(`${method}-response`);
    const originalMethod = descriptor.value;
    if (strategy === 'STALE_WHILE_REVALIDATE') {
      descriptor.value = function (...args: unknown[]) {
        return staleWhileRevalidateStrategy(args, originalMethod.bind(this), cache, {});
      };
    }
    if (strategy === 'CACHE_FIRST') {
      descriptor.value = function (...args: unknown[]) {
        return cacheFirstStrategy(args, originalMethod.bind(this), cache, config!);
      };
    }
  };
}
