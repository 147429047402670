import { FilterCondition } from './FilterCondition';
import { IDateFormat } from './Field';
import { ProgressVisualizationTypes } from '../enums';

export enum FieldType {
  NUMBER = 'number',
  TEXT = 'text',
  SINGLE_SELECT = 'single-select',
  MULTI_SELECT = 'multi-select',
  DATE = 'date',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
  LOOKUP = 'lookup',
  REFERENCE = 'reference',
  LONG_TEXT = 'long-text',
  BOOLEAN = 'boolean',
  FORMULA = 'formula',
  USER = 'user',
  CREATED_AT = 'created-at',
  CREATED_BY = 'created-by',
  UPDATED_AT = 'updated-at',
  UPDATED_BY = 'updated-by',
}

export interface IBaseFieldType {
  type: FieldType;
  supportedConditions: FilterCondition[];
}

export interface IBaseDateFieldType extends IBaseFieldType {
  dateFormats: Array<keyof IDateFormat>;
  timeFormats: string[];
}

export interface INumberFieldType extends IBaseFieldType {
  type: FieldType.NUMBER;
  precisions: number[];
}

export interface IPercentageType extends IBaseFieldType {
  type: FieldType.PERCENTAGE;
  precisions: number[];
  visualizationTypes: ProgressVisualizationTypes[];
}

export interface ICurrencyFieldType extends IBaseFieldType {
  type: FieldType.CURRENCY;
  precisions: number[];
  currencies: string[];
}

export interface IDateFieldType extends IBaseDateFieldType {
  type: FieldType.DATE;
}

export interface ITextFieldType extends IBaseFieldType {
  type: FieldType.TEXT;
}

export interface IBooleanFieldType extends IBaseFieldType {
  type: FieldType.BOOLEAN;
}

export interface ILongTextFieldType extends IBaseFieldType {
  type: FieldType.LONG_TEXT;
}

export interface ISingleSelectType extends IBaseFieldType {
  type: FieldType.SINGLE_SELECT;
}

export interface IMultiSelectType extends IBaseFieldType {
  type: FieldType.MULTI_SELECT;
}

export interface ILookupType extends IBaseFieldType {
  type: FieldType.LOOKUP;
}

export interface IReferenceFieldType extends IBaseFieldType {
  type: FieldType.REFERENCE;
}

export interface IFormulaFieldType extends IBaseFieldType {
  type: FieldType.FORMULA;
}

export interface IUserFieldType extends IBaseFieldType {
  type: FieldType.USER;
}

export interface ICreatedAtFieldType extends IBaseDateFieldType {
  type: FieldType.CREATED_AT;
}

export interface ICreatedByFieldType extends IBaseFieldType {
  type: FieldType.CREATED_BY;
}

export interface IUpdatedAtFieldType extends IBaseDateFieldType {
  type: FieldType.UPDATED_AT;
}

export interface IUpdatedByFieldType extends IBaseFieldType {
  type: FieldType.UPDATED_BY;
}

export type IFieldType =
  | INumberFieldType
  | IPercentageType
  | ICurrencyFieldType
  | IDateFieldType
  | ITextFieldType
  | ILongTextFieldType
  | ISingleSelectType
  | IMultiSelectType
  | ILookupType
  | IReferenceFieldType
  | IBooleanFieldType
  | IFormulaFieldType
  | IUserFieldType
  | ICreatedAtFieldType
  | ICreatedByFieldType
  | IUpdatedAtFieldType
  | IUpdatedByFieldType;
