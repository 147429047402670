/**
 * Predefined colors
 * the values are used in translation as message keys (and fallbacks)
 */
export enum PredefinedColors {
  BLUE_LIGHT = 'blueLight',
  CHARTREUSE = 'chartreuse',
  FUCHSIA = 'fuchsia',
  ORANGE = 'orange',
  RED = 'red',
  SEAFOAM_DARK = 'seafoamDark',
  BLUE_SKY = 'blueSky',
  INDIGO_DARK = 'indigoDark',
  MAGENTA = 'magenta',
  SEAFOAM_LIGHT = 'seafoamLight',
  BLUE_LIGHT_SKY = 'blueLightSky',
  INDIGO_LIGHT = 'indigoLight',
  YELLOW = 'yellow',
  PURPLE = 'purple',
  BLUE_DODGER = 'blueDodger',
  GRAY = 'gray',
}
