import { Field } from './Field';
import { IRecordType } from './RecordType';

export interface IBaseSection {
  name: string;
  displayName: string;
  sectionType: SectionType | null;
  default: boolean;
}
export interface IFieldsSection extends IBaseSection {
  fields: Array<Field['id']>;
}
export interface ISection extends IBaseSection {
  sections: ISection[] | IFieldsSection[];
}

export interface IRecordTypeLayout {
  id: string;
  recordTypeId: IRecordType['id'];
  displayName: string;
  layoutType?: LayoutType;
  sections: IFieldsSection[] | ISection[];

  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export enum LayoutType {
  DETAIL = 'DETAIL',
  BRIEF = 'BRIEF',
}

export enum SectionType {
  FIELD = 'FIELD',
  GROUP = 'GROUP',
}

export type RecordTypeLayoutDTO = Pick<
  IRecordTypeLayout,
  'displayName' | 'recordTypeId' | 'sections'
> &
  Partial<Pick<IRecordTypeLayout, 'id'>>;
