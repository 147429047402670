import { Field, ReferenceField } from './Field';
import { ITableView } from './View';
import { IAEMRecordConnectionOptions } from './AEMConnection';
import { MaestroObjects } from './MaestroObjects';

export interface IRecordData {
  [fieldId: string]: unknown;
}

export interface IRecord {
  id: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string | null;
  updatedAt: string | null;
  recordTypeId: string;
  thumbnailUrl: string | null;
  order: string | null;
  coverImage: {
    url: string | null;
    position: number;
  } | null;
  data: IRecordData;
  recordExternalOptions: {
    externalId: string;
    link: string;
    connectionOptions?: IAEMRecordConnectionOptions;
  } | null;
  metadata?: {
    isAIExtracted: boolean;
  } | null;
}

export interface IGroups {
  fieldId: string;
  groups: IGroups[];
  recordIds: string[];
  value: string | unknown[];
}

export interface ISearchRecordsResponse {
  records: IRecord[];
  totalCount: number;
  groups: IGroups[];
}

export type ISearchRecordsConnectionResponse = Omit<ISearchRecordsResponse, 'groups'>;

export type RecordDTO = Partial<Pick<IRecord, 'recordTypeId' | 'data'>>;

interface IRecordSearchBase {
  filters?: unknown[];
  /**
   * Server default: 100,
   * Max Limit: 1000
   */
  limit?: number;
  /**
   * Server default: 0
   */
  offset?: number;
  /**
   * An array of field ids to be grouped by with.
   */
  groupingFieldIds?: string[];
  sorting?: ITableView['sorting'];
  /**
   * rowOrder of table view
   */
  rowOrderViewId?: string;
}

export interface IRecordSearchDTO extends IRecordSearchBase, Pick<IRecord, 'recordTypeId'> {}

export interface IConnectionRecordSearchDTO extends IRecordSearchBase {
  /**
   * A field id to be searched by with.
   */
  referenceFieldId: ReferenceField['id'];
}

export enum bulkRecordDTOType {
  CREATE = 'recordsToCreate',
  UPDATE = 'recordsToUpdate',
  DELETE = 'recordIdsToDelete',
  RESTORE = 'recordIdsToRestore',
}

export interface IBulkRecordDTO {
  recordTypeId: string;
  [bulkRecordDTOType.CREATE]?: Array<{
    data: IRecordData;
  }>;
  [bulkRecordDTOType.UPDATE]?: Array<{
    data: IRecordData;
    id: string;
  }>;
  [bulkRecordDTOType.DELETE]?: string[];
  [bulkRecordDTOType.RESTORE]?: string[];
}
export interface IBulkResponse {
  inserts: IRecord[];
  updates: IRecord[];
  restores: IRecord[];
  deletesCount: number;
  insertsCount: number;
  updatesCount: number;
  restoresCount: number;
  hasErrors: boolean;
}

export interface IRecordTypeRecordsCount {
  [recordTypeId: string]: number;
}

export interface IActivityLogFieldState<LogFieldValue> {
  fieldSnapshot: Field;
  value: LogFieldValue;
}

export interface IRecordActivityLogItem<LogFieldValue> {
  after: IActivityLogFieldState<LogFieldValue>;
  before: IActivityLogFieldState<LogFieldValue> | null;
  createdAt: string;
  createdBy: string;
  customerId: string;
  fieldId: string;
  id: string;
  recordId: string;
}

export interface IRecentlyDeletedRecord {
  id: string;
  primaryFieldValue: string;
  recordTypeName: string;
  deletedBy: string;
  deletedAt: string;
  objectType: MaestroObjects.RECORD;
}
