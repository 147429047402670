import { FieldType } from './FieldType';
import { RollupType } from './RollupType';

export const RollupTypesPerFieldType: Record<FieldType, RollupType[]> = {
  [FieldType.NUMBER]: [
    RollupType.SUM,
    RollupType.AVG,
    RollupType.MIN,
    RollupType.MAX,
    RollupType.DIST,
  ],
  [FieldType.TEXT]: [RollupType.DIST],
  [FieldType.BOOLEAN]: [],
  [FieldType.SINGLE_SELECT]: [RollupType.DIST],
  [FieldType.MULTI_SELECT]: [RollupType.DIST],
  [FieldType.DATE]: [RollupType.MIN, RollupType.MAX, RollupType.DIST],
  [FieldType.PERCENTAGE]: [
    RollupType.SUM,
    RollupType.AVG,
    RollupType.MIN,
    RollupType.MAX,
    RollupType.DIST,
  ],
  [FieldType.CURRENCY]: [
    RollupType.SUM,
    RollupType.AVG,
    RollupType.MIN,
    RollupType.MAX,
    RollupType.DIST,
  ],
  [FieldType.LOOKUP]: [],
  [FieldType.REFERENCE]: [RollupType.DIST],
  [FieldType.LONG_TEXT]: [],
  [FieldType.FORMULA]: [RollupType.DIST],
  [FieldType.USER]: [],
  [FieldType.CREATED_AT]: [RollupType.MIN, RollupType.MAX, RollupType.DIST],
  [FieldType.CREATED_BY]: [],
  [FieldType.UPDATED_AT]: [RollupType.MIN, RollupType.MAX, RollupType.DIST],
  [FieldType.UPDATED_BY]: [],
};
