import BaseCrudApi from './BaseCrudApi';
import { ITemplate } from '../types';

export default class TemplateAPI extends BaseCrudApi<ITemplate> {
  protected ENDPOINT = 'templates';

  getAll(): Promise<ITemplate[]> {
    return this.client.get<ITemplate[]>(this.ENDPOINT);
  }
}
