import BaseCrudApi from './BaseCrudApi';
import { IUser, IUsersGroup } from '../types';

export default class UsersGroupAPI extends BaseCrudApi<IUser> {
  protected ENDPOINT = 'groups';

  async searchGroups(groupName?: string): Promise<IUsersGroup[]> {
    const params = new URLSearchParams();
    if (groupName !== undefined) {
      params.set('name', groupName);
    }
    return this.client.get<IUser[]>(`${this.ENDPOINT}?${params.toString()}`);
  }
}
