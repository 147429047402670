/**
 * Handles the case when the body is empty
 * for those cases the "content-type" is not "application/json"
 * e.g. workspace/recordType deletion
 */
export const responseHandler = (response: Response) => {
  const responseType = response.headers.get('content-type') || '';
  //"includes" is for "application/json;charset=UTF-8" (calculation engine calls)
  if (responseType.includes('application/json')) {
    return response.json();
  }
  if (
    responseType.includes('image/') ||
    responseType.includes('binary/octet-stream') ||
    responseType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
  ) {
    return response.blob();
  }
};
