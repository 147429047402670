export enum FilterCondition {
  CONTAINS = '$contains',
  DOES_NOT_CONTAIN = '$doesNotContain',
  IS = '$is',
  IS_NOT = '$isNot',
  IS_EMPTY = '$isEmpty',
  IS_NOT_EMPTY = '$isNotEmpty',
  GREATER_THAN = '$greaterThan',
  GREATER_THAN_OR_EQUAL = '$greaterThanOrEqual',
  LESS_THAN = '$lessThan',
  LESS_THAN_OR_EQUAL = '$lessThanOrEqual',
  // Date-specific
  IS_AFTER = '$isAfter',
  IS_BEFORE = '$isBefore',
  IS_BETWEEN = '$isBetween',
  IS_NOT_BETWEEN = '$isNotBetween',
  // Single/Multi-select-specific
  IS_ANY_OF = '$isAnyOf',
  IS_NONE_OF = '$isNoneOf',
  HAS_ANY_OF = '$hasAnyOf',
  HAS_ALL_OF = '$hasAllOf',
  HAS_NONE_OF = '$hasNoneOf',
  IS_EXACTLY = '$isExactly',
}
