import { IStorage } from '../types/Storage';
import { log } from '../utils/logger';

export class MaestroStorage implements IStorage {
  constructor(additionalPrefix: string) {
    this.prefix = `maestro|${additionalPrefix}|`;
  }

  private readonly prefix;

  private storage: Storage = localStorage;

  public get<T>(key: string): T | null {
    const prefixedKey = this.getKey(key);
    return this.deserialize(this.storage.getItem(prefixedKey) ?? '');
  }

  public set(key: string, value: unknown) {
    const prefixedKey = this.getKey(key);
    try {
      this.storage.setItem(prefixedKey, this.serialize(value));
    } catch (error) {
      log.error(error);
    }
  }

  public remove(key: string): void {
    const prefixedKey = this.getKey(key);
    this.storage.removeItem(prefixedKey);
  }

  private serialize(value: unknown): string {
    return JSON.stringify(value);
  }

  protected getKey(key: string): string {
    return `${this.prefix}${key}`;
  }

  private deserialize<T>(value: string): T | null {
    try {
      return value ? JSON.parse(value) : null;
    } catch (error) {
      log.error(error);
      return null;
    }
  }
}
