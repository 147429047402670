import { Cacheable } from '../cache/Cacheable';
import { IFormulaAPIValidationError, IFormulaExpression } from '../types';
import BaseApi from './BaseApi';

export default class CalculationEngineApi extends BaseApi {
  protected ENDPOINT = '/calculationengine/formulas';

  @Cacheable('STALE_WHILE_REVALIDATE')
  getExpressionTypes(localeLanguageCode: string): Promise<Record<string, IFormulaExpression[]>> {
    return this.client.get<Record<string, IFormulaExpression[]>>(
      `${this.ENDPOINT}/getExpressionTypes?localeLanguageCode=${localeLanguageCode}&applicationScope=MAESTRO`
    );
  }

  validate(
    formula: string,
    recordTypeId: string
  ): Promise<{ errors: IFormulaAPIValidationError[] }> {
    return this.client.post(`${this.ENDPOINT}/validate`, {
      formula,
      objCodes: [recordTypeId],
      connectorType: 'MAESTRO',
    });
  }
}
