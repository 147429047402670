import { envMappingToAdobeIOUrl } from '../constants';

type TShellEnvironment = keyof typeof envMappingToAdobeIOUrl;

export const isValidEnvironment: (env?: string) => asserts env is TShellEnvironment = (
  env?: string
) => {
  if (!env || !(env in envMappingToAdobeIOUrl)) {
    throw new Error(`Invalid environment name: "${env}"`);
  }
};
