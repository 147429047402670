export enum UserType {
  WF = 'WF',
}

export interface IUser {
  id: string;
  name: string;
  isActive: boolean;
  isAdmin: boolean;
  email: string;
  userType: UserType;
  avatarDownloadURL: string;
  role: {
    ID: string;
    name: string;
  };
}
