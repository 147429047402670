import { ILocalizableMessage, MaestroErrorCode } from '../types';

export const MAX_RECORD_TYPE_COUNT_PER_WORKSPACE = 100;
export const MAX_VIEWS_COUNT_PER_RECORD_TYPE = 100;
export const MAX_PARAGRAPH_FIELD_PER_RECORD_TYPE = 20;
export const messagesByCode: { [c in MaestroErrorCode]: ILocalizableMessage | null } = {
  [MaestroErrorCode.BAD_REQUEST]: null,
  [MaestroErrorCode.INVALID_DATA]: null,
  [MaestroErrorCode.UNAUTHORIZED]: null,
  [MaestroErrorCode.FORBIDDEN]: null,
  [MaestroErrorCode.NO_ACCESS]: null,
  [MaestroErrorCode.ENTITY_WITH_ATTRIBUTE_NOT_FOUND]: null,
  [MaestroErrorCode.INTERNAL_SERVER_ERROR]: null,
  [MaestroErrorCode.NOT_FOUND]: null,
  [MaestroErrorCode.INVALID_DISPLAYNAME_CHARACTERS]: {
    key: 'invalid.characters',
    fallback: "A record name cannot contain any of the following characters: '{' '}' | \\",
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.WORKSPACE_LIMIT_EXCEEDED]: {
    key: 'workspace.max.limit',
    fallback: 'Your organization has reached the maximum of {0} workspaces',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.RECORD_TYPE_LIMIT_EXCEEDED]: {
    key: 'recordType.max.limit',
    fallback: 'This workspace has the maximum of {0} record types and taxonomies',
    args: [MAX_RECORD_TYPE_COUNT_PER_WORKSPACE],
    conflictList: null,
  },
  [MaestroErrorCode.FIELD_LIMIT_EXCEEDED]: {
    key: 'field.max.limit',
    fallback: 'You reached the maximum of {0} fields for one record type',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.ALLOW_NEGATIVE_CHANGE_DISALLOWED]: {
    key: 'fieldType.update.allow.negative.change.disallowed',
    fallback: 'Unable to update the field. Negative values already exist.',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.ALLOW_MULTIPLE_USERS_CHANGE_DISALLOWED]: {
    key: 'fieldType.update.user.allow.multiple.change.disallowed',
    fallback: 'Unable to update the field. Multiple values already exist',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.PERSONAL_VIEW_LIMIT_EXCEEDED]: {
    key: 'personal.view.limit.exceeded',
    fallback: 'You created the maximum of {0} views. You must delete a view to create more.',
    args: [MAX_VIEWS_COUNT_PER_RECORD_TYPE],
    conflictList: null,
  },
  [MaestroErrorCode.FIELD_LOOKUP_DEPTH_LIMIT_EXCEEDED]: {
    key: 'field.lookup.depth.limit.exceeded',
    fallback: 'You cannot look up a value that is more than five record types away',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.BACK_REFERENCE_RECORD_ALREADY_CONNECTED]: {
    key: 'back.reference.record.already.connected',
    fallback: 'Back reference record is already connected to {0}',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.PARAGRAPH_FIELD_LIMIT_REACHED]: {
    key: 'paragraph.field.limit.reached',
    fallback:
      'The number of paragraph fields for this record type has reached the maximum limit of {0}.',
    args: [MAX_PARAGRAPH_FIELD_PER_RECORD_TYPE],
    conflictList: null,
  },
  [MaestroErrorCode.CUSTOMER_RECORD_LIMIT_EXCEEDED]: {
    key: 'customer.record.limit.exceeded',
    fallback:
      'The maximum number of records allowed for your instance has been reached. You can delete records that are no longer needed or contact your system administrator for more information',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.WORKSPACE_RECORD_LIMIT_EXCEEDED]: {
    key: 'workspace.record.limit.exceeded',
    fallback:
      'The maximum number of records allowed in a workspace has been reached. You can delete records that are no longer needed or contact your system administrator for more information.',
    args: [],
    conflictList: null,
  },
  [MaestroErrorCode.RECORD_TYPE_RECORD_LIMIT_EXCEEDED]: {
    key: 'recordType.record.limit.exceeded',
    fallback:
      'The maximum number of records allowed for this record type has been reached. You can delete records that are no longer needed to add more records.',
    args: [],
    conflictList: null,
  },
};

export const getMessageByCode = (
  code: MaestroErrorCode,
  conflictList: ILocalizableMessage['conflictList'],
  args?: Array<string | number>
): ILocalizableMessage | null => {
  const message = messagesByCode[code] || null;
  const argsData = args ? { args } : {};
  const conflictListData = conflictList ? { conflictList } : {};

  return message ? { ...message, ...argsData, ...conflictListData } : message;
};
