import { IFieldType } from '../types/FieldType';
import { AbstractApiClient } from './AbstractApiClient';

export default class FieldTypeAPI {
  protected client: AbstractApiClient;
  protected ENDPOINT = 'field-types';
  constructor(client: AbstractApiClient) {
    this.client = client;
  }

  getAll(): Promise<IFieldType[]> {
    return this.client.get<IFieldType[]>(this.ENDPOINT);
  }
}
