import { IPermissionRequests } from '../types/PermissionRequests';
import { AbstractApiClient } from './AbstractApiClient';

export default class PermissionRequests {
  protected client: AbstractApiClient;
  protected ENDPOINT = 'permission-requests';
  constructor(client: AbstractApiClient) {
    this.client = client;
  }

  get(
    resourceId: IPermissionRequests['resourceId'],
    resourceType: IPermissionRequests['resourceType']
  ): Promise<IPermissionRequests[]> {
    return this.client.get<IPermissionRequests[]>(
      `${this.ENDPOINT}?${new URLSearchParams({
        resourceId,
        resourceType,
      }).toString()}`
    );
  }

  bulkDelete(data: {
    permissionRequestIds: Array<IPermissionRequests['resourceId']>;
    resourceId: IPermissionRequests['resourceId'];
    resourceType: IPermissionRequests['resourceType'];
  }): Promise<void> {
    return this.client.delete(`${this.ENDPOINT}`, data);
  }

  create: (
    permissionRequest: Pick<IPermissionRequests, 'message' | 'resourceId' | 'resourceType'>
  ) => Promise<IPermissionRequests> = (permissionRequest) =>
    this.client.post<IPermissionRequests>(this.ENDPOINT, permissionRequest);
}
