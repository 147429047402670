import BaseCrudApi from './BaseCrudApi';
import { IRecordTypeLayout, RecordTypeLayoutDTO } from '../types';

export default class RecordTypeLayoutAPI extends BaseCrudApi<IRecordTypeLayout> {
  protected ENDPOINT = 'record-layouts';

  create(recordTypeLayout?: RecordTypeLayoutDTO): Promise<IRecordTypeLayout> {
    return this.client.post<IRecordTypeLayout>(this.ENDPOINT, recordTypeLayout);
  }

  getByRecordType(recordTypeId: IRecordTypeLayout['id']): Promise<IRecordTypeLayout> {
    return this.client.get<IRecordTypeLayout>(`${this.ENDPOINT}/record-type/${recordTypeId}`);
  }

  getLayoutsByRecordType(recordTypeId: IRecordTypeLayout['id']): Promise<IRecordTypeLayout[]> {
    return this.client.get<IRecordTypeLayout[]>(
      `${this.ENDPOINT}?${new URLSearchParams({ recordTypeId }).toString()}`
    );
  }
}
