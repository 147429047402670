import { ReactNode } from 'react';
import { ToastOptions } from '@react-stately/toast';

export enum NotificationBannerType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  INFO = 'info',
}

export interface INotificationBanner {
  id?: string;
  content: ReactNode;
  type: NotificationBannerType;
  actionLabel?: string;
  onAction?: () => void;
  options?: ToastOptions;
}
