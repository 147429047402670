import { SpectrumToastOptions } from '@react-spectrum/toast';

export enum ToastType {
  NEUTRAL = 'neutral',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  INFO = 'info',
}

export interface IToast {
  content: string;
  id?: string;
  type: ToastType;
  options?: SpectrumToastOptions;
}
