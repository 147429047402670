import { MaestroError } from './MaestroError';
import { log } from '../utils/logger';

export const assertHandledError = (error: unknown): asserts error is MaestroError => {
  if (error instanceof MaestroError) {
    if (error.isGenericError()) {
      // log a warning in datadog about generic error
      log.warn(error.message, error);
    }
  } else {
    throw error;
  }
};
