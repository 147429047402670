import CalculationEngineApi from './CalculationEngineApi';
import FieldTypeAPI from './FieldTypeAPI';
import RecordAPI from './RecordAPI';
import RecordTypeAPI from './RecordTypeAPI';
import WorkspaceAPI from './WorkspaceAPI';
import RecordTypeLayoutAPI from './RecordTypeLayoutAPI';
import UserMetadataAPI from './UserMetadataAPI';
import ViewAPI from './ViewAPI';
import { AbstractApiClient } from './AbstractApiClient';
import ConnectionAPI from './ConnectionAPI';
import UserAPI from './UserAPI';
import FieldAPI from './FieldAPI';
import RelationsAPI from './RelationsAPI';
import UsersGroupAPI from './UsersGroupAPI';
import PermissionRequests from './PermissionRequests';
import GenerativeAssetsApi from './GenerativeAssetsApi';
import ExternalRecordAPI from './ExternalRecordAPI';
import TemplateAPI from './TemplateAPI';
import RecordTypePageAPI from './RecordTypePageAPI';
import AssetsAPI from './AssetsApi';

export default class MaestroAPI {
  public template: TemplateAPI;
  public workspace: WorkspaceAPI;
  public record: RecordAPI;
  public recordType: RecordTypeAPI;
  public fieldType: FieldTypeAPI;
  public recordTypeLayout: RecordTypeLayoutAPI;
  public recordTypePage: RecordTypePageAPI;
  public userMetadata: UserMetadataAPI;
  public view: ViewAPI;
  public connection: ConnectionAPI;
  public user: UserAPI;
  public usersGroup: UsersGroupAPI;
  public calculationEngineAPI: CalculationEngineApi;
  public field: FieldAPI;
  public relations: RelationsAPI;
  public permissionRequests: PermissionRequests;
  public generativeAssets: GenerativeAssetsApi;
  public assets: AssetsAPI;
  public externalRecord: ExternalRecordAPI;

  constructor(client: AbstractApiClient) {
    this.template = new TemplateAPI(client);
    this.workspace = new WorkspaceAPI(client);
    this.record = new RecordAPI(client);
    this.recordType = new RecordTypeAPI(client);
    this.fieldType = new FieldTypeAPI(client);
    this.recordTypeLayout = new RecordTypeLayoutAPI(client);
    this.recordTypePage = new RecordTypePageAPI(client);
    this.userMetadata = new UserMetadataAPI(client);
    this.view = new ViewAPI(client);
    this.connection = new ConnectionAPI(client);
    this.user = new UserAPI(client);
    this.usersGroup = new UsersGroupAPI(client);
    this.calculationEngineAPI = new CalculationEngineApi(client);
    this.field = new FieldAPI(client);
    this.relations = new RelationsAPI(client);
    this.permissionRequests = new PermissionRequests(client);
    this.generativeAssets = new GenerativeAssetsApi(client);
    this.externalRecord = new ExternalRecordAPI(client);
    this.assets = new AssetsAPI(client);
  }
}
