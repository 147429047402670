import { ConnectionType, IRecord, IWorkspace } from '../types';
import { AbstractApiClient } from './AbstractApiClient';

export default class ExternalRecordAPI {
  protected ENDPOINT = 'external-record';
  protected client: AbstractApiClient;
  constructor(client: AbstractApiClient) {
    this.client = client;
  }

  getExternalRecordsWithWorkspaces(
    connection: ConnectionType,
    object: string,
    externalId: string
  ): Promise<Array<{ externalRecord: IRecord; workspace: IWorkspace }>> {
    const params = new URLSearchParams();
    params.set('connection', connection);
    params.set('object', object);
    params.set('externalId', externalId);
    return this.client.get(`${this.ENDPOINT}/workspaces?${params.toString()}`);
  }

  getExternalRecordsWithAllWorkspaces(
    connection: ConnectionType,
    object: string,
    externalId: string
  ): Promise<Array<{ externalRecord: IRecord; workspace: IWorkspace }>> {
    const params = new URLSearchParams();
    params.set('connection', connection);
    params.set('object', object);
    params.set('externalId', externalId);
    return this.client.get(`${this.ENDPOINT}/workspaces/global?${params.toString()}`);
  }
}
