import { useCallback, useEffect } from 'react';
import mitt, { Handler } from 'mitt';

import { MaestroEvents } from '../types';

export const emitter = mitt<MaestroEvents>();
export const useEmitter = <Key extends keyof MaestroEvents>(
  event: Key,
  callback: Handler<MaestroEvents[Key]>
) => {
  const callbackMemo = useCallback(callback, [callback]);

  useEffect(() => {
    emitter.on(event, callbackMemo);

    return () => {
      emitter.off(event, callbackMemo);
    };
  }, [callbackMemo, event]);
};

export const getEmitter = <T extends Record<string, unknown>>() => mitt<T>();
