import {
  AccessPermission,
  CreateWorkspaceDTO,
  IWorkspace,
  IWorkspaceAllAttributes,
  IWorkspacesResponse,
} from '../types';
import { ApiClientFetchOptions } from './AbstractApiClient';
import BaseCrudApi from './BaseCrudApi';

export default class WorkspaceAPI extends BaseCrudApi<IWorkspace> {
  protected ENDPOINT = 'workspaces';

  get(id: string, attributes: string[] = ['*']): Promise<IWorkspace> {
    return this.client.get<IWorkspace>(`${this.ENDPOINT}/${id}?attributes=${attributes.join(',')}`);
  }

  getAll<T extends IWorkspaceAllAttributes = undefined>(
    attributes?: T,
    permissions: AccessPermission[] = []
  ): Promise<IWorkspacesResponse<T>> {
    const permissionsQuery = permissions.length ? `&permissions=${permissions.join(',')}` : '';
    let attributesArray = [];
    if (!attributes) {
      attributesArray.push('*');
    } else {
      attributesArray = attributes;
    }
    return this.client.get<IWorkspacesResponse<T>>(
      `${this.ENDPOINT}?attributes=${attributesArray.join(',')}${permissionsQuery}`
    );
  }

  getConnections(workspaceId: string, fetchOptions?: ApiClientFetchOptions): Promise<IWorkspace[]> {
    return this.client.get<IWorkspace[]>(
      `${this.ENDPOINT}/connections/${workspaceId}`,
      fetchOptions
    );
  }

  create(workspace: CreateWorkspaceDTO): Promise<IWorkspace> {
    return this.client.post<IWorkspace>(this.ENDPOINT, { workspace });
  }

  createFromTemplate(templateId: string): Promise<IWorkspace> {
    return this.client.post<IWorkspace>(this.ENDPOINT, { templateId });
  }

  createGenStudioWorkspace(): Promise<IWorkspace> {
    return this.client.post<IWorkspace>(`${this.ENDPOINT}/genstudio`, {});
  }

  getGenStudioWorkspace(): Promise<IWorkspace> {
    return this.client.get<IWorkspace>(`${this.ENDPOINT}/genstudio/aliased`);
  }
}
