import { ICacheStrategyImplementation } from '../../types/CacheStrategy';

export const staleWhileRevalidateStrategy: ICacheStrategyImplementation = async (
  args,
  valueGetter,
  maestroStorage
) => {
  const cacheKey = JSON.stringify(args);
  const cachedValue = maestroStorage.get<{ value: unknown }>(cacheKey);

  const getAndSetValue = () => {
    maestroStorage.remove(cacheKey);
    return valueGetter(...args)
      .then((value) => {
        maestroStorage.set(cacheKey, { value });
        return value;
      })
      .catch((e) => {
        maestroStorage.remove(cacheKey);
        throw e;
      });
  };

  if (cachedValue && cachedValue.value) {
    getAndSetValue();
    return cachedValue.value;
  }

  return getAndSetValue();
};
