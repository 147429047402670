import { IImageGenerationPayload } from '../types';
import { ApiClientFetchOptions } from './AbstractApiClient';
import BaseApi from './BaseApi';

export default class GenerativeAssets extends BaseApi {
  protected ENDPOINT = 'generative-assets';

  generateImages(
    imageSpecifications: IImageGenerationPayload,
    options?: ApiClientFetchOptions
  ): Promise<string[]> {
    return this.client.post<string[]>(`${this.ENDPOINT}/images`, imageSpecifications, options);
  }
}
