import { Field, FieldDTO } from '../types';
import BaseCrudApi from './BaseCrudApi';

export default class FieldAPI extends BaseCrudApi<Field> {
  protected ENDPOINT = 'fields';
  protected BULK_ENDPOINT = 'fields/bulk';

  create(fieldData: FieldDTO, recordTypeId: string): Promise<Field> {
    return this.client.post<Field>(
      `${this.ENDPOINT}?${new URLSearchParams({ recordTypeId }).toString()}`,
      fieldData
    );
  }

  bulkCreate(fields: FieldDTO[], recordTypeId: string): Promise<Field[]> {
    return this.client.post<Field[]>(`${this.BULK_ENDPOINT}?recordTypeId=${recordTypeId}`, fields);
  }
}
