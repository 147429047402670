import BaseApi from './BaseApi';

export default abstract class BaseCrudApi<DataType> extends BaseApi {
  get(id: string): Promise<DataType> {
    return this.client.get<DataType>(`${this.ENDPOINT}/${id}`);
  }

  delete(id: string) {
    return this.client.delete<DataType>(`${this.ENDPOINT}/${id}`);
  }

  update(id: string, updateData: Partial<DataType>): Promise<DataType> {
    return this.client.patch<DataType>(`${this.ENDPOINT}/${id}`, updateData) as Promise<DataType>;
  }
}
